










































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import CardLoading from '@/components/CardLoading/index.vue';
import SubManageDialog from './SubManageDialog.vue';
import { CurriculumManagementService } from '@/services/abilityAssessment/curriculum-management-service';
import { BasicInformationService } from '@/services/abilityAssessment/basic-information-service';
import { deepCopy, keyValueConversion, addRandomKey } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: { CardLoading, SubManageDialog }
})
export default class ManageDialog extends Vue {
    @Prop(String) private dialogType!: string;
    @Prop() private defaultData: object | null;
    @Emit() cancel(isRefresh: boolean = false) {
        return isRefresh;
    }
    @Inject(CurriculumManagementService)
    private curriculumManagementService!: CurriculumManagementService;
    @Inject(BasicInformationService)
    private basicInformationService!: BasicInformationService;

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public DETAIL: string = DETAIL;
    public formInfo: any = {
        skillAssessmentId: '',
        primaryAssessmentTypes: []
    };
    public rules: any = {
        skillAssessmentId: [
            { required: true, message: '请选择测评单标题', trigger: 'change' }
        ]
    };
    public skillAssessmentTitle: string = "";
    public labelPosition: string = 'right';
    public title: string = '';
    public tableData: any[] = [];
    public loading: Boolean = false;
    public cardLoading: Boolean = false;
    public visible: boolean = true;
    public assessmentOptions: any[] = [];
    // 子Dialog相关
    public subDialogVisible: boolean = false;
    public subDialogType: string = '';
    public subCurrentIndex: number = -1;

    public currentMainIndex: number = -1;
    public currentSubIndex: number = -1;
    public currentAssessmentIndex: number = -1;
    public currentLevelIndex: number = -1;
    public currentRowIndex: number = -1;
    public currentRowData: any = null;

    public created(): void {
        this.initData();
    }
    // 清空数据
    public clearData() {
        this.$set(this.formInfo.primaryAssessmentTypes[this.currentMainIndex].subAssessmentTypes[this.currentSubIndex].assessmentTypeItems[this.currentAssessmentIndex].typeItemLevels[this.currentLevelIndex], "multipleSelectionKey", []);
        (this.$refs[`multipleTable_${this.currentMainIndex}_${this.currentSubIndex}_${this.currentAssessmentIndex}_${this.currentLevelIndex}`][0] as any).clearSelection();
        this.subDialogType = "";
        this.currentMainIndex = -1;
        this.currentSubIndex = -1;
        this.currentAssessmentIndex = -1;
        this.currentLevelIndex = -1;
        this.currentRowIndex = -1;
        this.currentRowData = null;
    }
    // 初始化数据
    public async initData() {
        switch (this.dialogType) {
            case ADD:
                this.title = '新增结果对应课程';
                this.getAssessmentList();
                break;
            case EDIT:
                this.title = '编辑结果对应课程';
                const { skillAssessmentId, title } = this.defaultData as any;
                // 赋值测评单值
                this.skillAssessmentTitle = title;
                this.$set(this.formInfo, "skillAssessmentId", skillAssessmentId);
                this.getAssessmentData(skillAssessmentId);
                break;
        }
    }
    // 拦截测评单的select点击事件
    public async handleSelectClick(val: string | number) {
        let prevVal = this.formInfo.skillAssessmentId;
        // 首次选择时，直接赋值不做提示
        if (!prevVal) {
            this.changeAssessment(val);
            (this.$refs.selectAssessment as any).blur();
        } else {
            // 重复点击同一选项时，不处理点击事件
            if (prevVal == val) {
                (this.$refs.selectAssessment as any).blur();
                return
            };
            // 变更选项时，提示是否确认变更
            try {
                await this.$confirm('当前信息将会丢失，请确认是否要切换测评单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                this.changeAssessment(val);
            } catch (e) { }
        }
    }
    // 改变测评单
    public changeAssessment(val: number | string) {
        // 赋值测评单值
        this.$set(this.formInfo, "skillAssessmentId", val);
        // 获取测评单对应数据
        this.getAssessmentData(val);
    }
    // 获取测评单数据
    public async getAssessmentData(id: number | string) {
        // 获取测评单的详情数据
        this.cardLoading = true;
        let res = await this.basicInformationService.getDetailById(id);
        if (!res) {
            this.cardLoading = false;
            return
        }
        let _primaryAssessmentTypes = deepCopy(res.primaryAssessmentTypes);
        if (this.dialogType === ADD) {
            _primaryAssessmentTypes = _primaryAssessmentTypes.map(t => {
                t.subAssessmentTypes = t.subAssessmentTypes.map(j => {
                    let activeAssessments = [];
                    if (j.assessmentTypeItems && j.assessmentTypeItems.length) {
                        // 赋值activeAssessments(用assessmentTypeItems下的所有id)，默认全部展开折叠面板
                        activeAssessments = j.assessmentTypeItems.map(k => k.id);
                        /**
                         * 添加 courses 用户存放课程
                         * 添加 multipleSelectionKey 用于记录已选课程key
                        */
                        j.assessmentTypeItems = j.assessmentTypeItems.map(k => ({ ...k, typeItemLevels: k.typeItemLevels.map(h => ({ ...h, courses: [], multipleSelectionKey: [] })) }))
                    }
                    return { ...j, activeAssessments }
                })
                return { ...t, activeSubTypeId: t.subAssessmentTypes[0].skillTypeId.toString() }
            })
            this.$set(this.formInfo, "primaryAssessmentTypes", _primaryAssessmentTypes);
        } else if (this.dialogType === EDIT) {
            _primaryAssessmentTypes = _primaryAssessmentTypes.map(t => {
                t.subAssessmentTypes = t.subAssessmentTypes.map(j => {
                    let activeAssessments = [];
                    if (j.assessmentTypeItems && j.assessmentTypeItems.length) {
                        // 赋值activeAssessments(用assessmentTypeItems下的所有id)，默认全部展开折叠面板
                        activeAssessments = j.assessmentTypeItems.map(k => k.id);
                        /**
                         * 给课程添加随机key
                         * 添加 multipleSelectionKey 用于记录已选课程key
                        */
                        j.assessmentTypeItems = j.assessmentTypeItems.map(k => {
                            let _typeItemLevels = k.typeItemLevels.map(h => {
                                if (h.courses && h.courses.length) {
                                    h.courses = addRandomKey(h.courses);
                                } else {
                                    h["courses"] = [];
                                }
                                return { ...h, multipleSelectionKey: [] }
                            });
                            return { ...k, typeItemLevels: _typeItemLevels }
                        })
                    }
                    return {
                        ...j,
                        activeAssessments
                    }
                })
                return { ...t, activeSubTypeId: t.subAssessmentTypes[0].skillTypeId.toString() }
            })
            this.$set(this.formInfo, "primaryAssessmentTypes", _primaryAssessmentTypes);
        }
        this.cardLoading = false;
        console.log("cardLoading", this.cardLoading);
        console.log("formInfo", this.formInfo);
    }
    // 获取测评单列表
    public async getAssessmentList() {
        const res = await this.curriculumManagementService.getDropdown();
        if (res && res.length) {
            this.assessmentOptions = res.map(t => ({
                label: t.title,
                value: t.skillAssessmentId
            }))
        }
    }
    // 切换子类型
    public changeSubType(mainIdx: number, tab, event) {
        console.log("切换子类型", mainIdx, tab, event);
    }
    // 切换折叠面板
    public changeCollapse(val: string | number, mainIdx: number, subIdx: number) {
        console.log("切换折叠面板", val, mainIdx, subIdx);
        console.log(this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].activeAssessments);
    }
    // 多选
    public handleSelectionChange(mainIdx: number, subIdx: number, assessmentIdx: number, levelIdx: number, val: any[]) {
        this.$set(this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].assessmentTypeItems[assessmentIdx].typeItemLevels[levelIdx], "multipleSelectionKey", val.map(t => t.key));
    }
    // 新增/编辑/查看
    public async handManage(type: string, mainIdx: number, subIdx: number, assessmentIdx: number, levelIdx: number, rowIdx: number, row?: any) {
        console.log("新增/编辑/查看", type, mainIdx, subIdx, assessmentIdx, levelIdx, rowIdx, row);
        this.subDialogType = type;
        this.currentMainIndex = mainIdx;
        this.currentSubIndex = subIdx;
        this.currentAssessmentIndex = assessmentIdx;
        this.currentLevelIndex = levelIdx;
        this.currentRowIndex = rowIdx;
        if (type === ADD) {
            this.currentRowData = null;
        } else if (type === EDIT) {
            this.currentRowData = deepCopy(row);
        }
        this.subDialogVisible = true;
    }
    // 删除
    public async handDelete(mainIdx: number, subIdx: number, assessmentIdx: number, levelIdx: number, rowIdx: number, row: any): Promise<void> {
        try {
            await this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            // 执行前端逻辑删除
            this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].assessmentTypeItems[assessmentIdx].typeItemLevels[levelIdx].courses.splice(rowIdx, 1);
            this.$message({
                message: '删除成功',
                type: 'success'
            });
        } catch (e) {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        }
    }
    // 批量删除
    public async handBatchDelete(mainIdx: number, subIdx: number, assessmentIdx: number, levelIdx: number): Promise<void> {
        try {
            await this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            // 执行前端逻辑删除
            let multipleSelectionKey = this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].assessmentTypeItems[assessmentIdx].typeItemLevels[levelIdx].multipleSelectionKey;
            let newArr = this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].assessmentTypeItems[assessmentIdx].typeItemLevels[levelIdx].courses.filter(t => (!multipleSelectionKey.includes(t.key)));
            this.$set(this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].assessmentTypeItems[assessmentIdx].typeItemLevels[levelIdx], "courses", newArr);
            this.$set(this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].assessmentTypeItems[assessmentIdx].typeItemLevels[levelIdx], "multipleSelectionKey", []);
            this.$message({
                message: '删除成功',
                type: 'success'
            });
        } catch (e) {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        }
    }
    // 保存子Dialog
    public subDialogSubmit(data: any) {
        let _items = deepCopy(this.formInfo.primaryAssessmentTypes[this.currentMainIndex].subAssessmentTypes[this.currentSubIndex].assessmentTypeItems[this.currentAssessmentIndex].typeItemLevels[this.currentLevelIndex].courses);
        this.subDialogVisible = false;
        // 赋值当前table
        if (this.subDialogType === ADD) {
            _items.push({ ...deepCopy(data), key: new Date().getTime() });
        } else {
            this.$set(_items, this.currentRowIndex, deepCopy(data));
        }
        this.$set(this.formInfo.primaryAssessmentTypes[this.currentMainIndex].subAssessmentTypes[this.currentSubIndex].assessmentTypeItems[this.currentAssessmentIndex].typeItemLevels[this.currentLevelIndex], "courses", _items);
    }
    // 关闭子Dialog
    public subDialogCancel() {
        this.subDialogVisible = false;
        this.clearData();
    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel();
        } catch (e) { }
    }
    // 转换数据格式
    public convertData(arr: any[]): any[] {
        let _arr = deepCopy(arr);
        let courses = [];
        _arr.map(t => {
            t.subAssessmentTypes.map(k => {
                if (k.assessmentTypeItems && k.assessmentTypeItems.length) {
                    k.assessmentTypeItems.map(j => {
                        j.typeItemLevels.map(h => {
                            if (h.courses && h.courses.length) {
                                h.courses.map(m => {
                                    courses.push({
                                        ...m,
                                        skillAssessmentCourseId: m.id ? m.id : null,
                                        skillAssessmentTypeItemLevelId: h.id
                                    })
                                })
                            }
                        })
                    })
                }
            })
        })
        return courses
    }
    // 保存表单
    public submitForm(): void {
        (this.$refs.formInfo as any).validate(async (valid: any) => {
            if (!valid) return;
            const params = {
                skillAssessmentId: this.formInfo.skillAssessmentId,
                courses: this.convertData(this.formInfo.primaryAssessmentTypes),
            };
            console.log('params', params);
            const res = await this.curriculumManagementService.saveCourse(params);
            if (res) {
                this.$message({
                    message: this.dialogType === ADD ? '添加成功' : '修改成功',
                    type: 'success'
                });
                this.cancel(true);
            }
        });
    }
}
