




















































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL } from '@/config/constant';
import ManageDialog from './components/ManageDialog.vue';
import { CurriculumManagementService } from '@/services/abilityAssessment/curriculum-management-service';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
  components: { ManageDialog }
})
export default class typeManagement extends Vue {
  @Inject(CurriculumManagementService)
  private curriculumManagementService!: CurriculumManagementService;

  public ADD: string = ADD;
  public EDIT: string = EDIT;
  public DETAIL: string = DETAIL;
  public form: any = {
    size: 10,
    current: 1,
    total: 0
  };
  public file: any[] = [];
  public tableData: any[] = [];
  public failReason: any[] = [];
  public loading: Boolean = false;
  public uploadLoading: Boolean = false;
  public downLoadLoading: Boolean = false;
  public exportLoading: Boolean = false;
  // Dialog相关
  public dialogVisible: boolean = false;
  public dialogType: string = '';
  public currentRow: any = null;

  public async created(): Promise<void> {
    await this.getList();
  }
  // 清空数据
  public clearData() {
    this.dialogType = '';
    this.currentRow = null;
  }
  // 搜索
  public search() {
    this.form.current = 1;
    this.getList();
  }
  // 分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  // 关闭新建/编辑Dialog
  public dialogCancel(isRefresh: boolean) {
    this.clearData();
    this.dialogVisible = false;
    // 刷新列表
    isRefresh && this.getList();
  }
  // 下载模板
  public async downLoadClick() {
    this.downLoadLoading = true;
    await this.curriculumManagementService.downLoadTemplate(`课程模板_${new Date().getTime()}.xlsx`);
    this.$message({
      message: '下载成功',
      type: 'success'
    });
    this.downLoadLoading = false;
  }
  // 导入
  public async importClick(file: any) {
    this.uploadLoading = true;
    // this.file = [file];
    const res = await this.curriculumManagementService.importFile(file);
    if (res) {
      // 有任何错误数据，认为整体导入失败
      let message = "";
      if (res.failureCount > 0) {
        let failureMsg = "";
        res.failures.map(t => {
          failureMsg += `<br>行号${t.line}：${t.message}`
        })
        message = `导入失败: ${res.failureCount}${failureMsg}</span>`;
      } else {
        message = `导入成功: ${res.successCount}`;
        this.getList();
      }
      this.$notify({
        title: res.failureCount > 0 ? "导入失败" : "导入成功",
        message,
        dangerouslyUseHTMLString: true,
        type: res.failureCount > 0 ? "error" : "success",
      });
      this.uploadLoading = false;
    }
  }
  // 导出
  public async exportClick() {
    if (!this.tableData.length) {
      this.$message({
        type: 'error',
        message: '当前无导出内容！'
      })
      return;
    }
    const params = {
      title: this.form.title,
      filename: `结果对应课程_${new Date().getTime()}.xlsx`
    }
    this.exportLoading = true;
    await this.curriculumManagementService.exportFile(params);
    this.$message({
      message: '导出成功',
      type: 'success'
    });
    this.exportLoading = false;
  }
  // 获取数据源
  public async getList() {
    this.loading = true;
    const res = await this.curriculumManagementService.getCourseList(this.form);
    if (res) {
      this.tableData = res.records || [];
      this.form.total = res.total;
      this.form.size = res.size;
      this.form.current = res.current;
    }
    this.loading = false;
  }
  // 新增/编辑/查看
  public async handManage(type: string, row?: any) {
    this.dialogType = type;
    this.currentRow = type !== ADD ? deepCopy(row) : null;
    this.dialogVisible = true;
  }
  // 删除
  public async handDelete(row: any): Promise<void> {
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      let res = await this.curriculumManagementService.removeCourse(row.skillAssessmentId);
      if (res) {
        this.getList();
        this.$message({
          message: '删除成功',
          type: 'success'
        });
      }
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  // 重置
  public reset() {
    this.form = {
      size: 10,
      current: 1,
      total: 0
    };
    this.search();
  }
}
