import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class CurriculumManagementService {
  @Inject(HttpService) private http!: HttpService;
  // 保存
  public saveCourse(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/course/save', param);
  }
  // 查询
  public getCourseList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-skill-backend/admin/course/page', param);
  }
  // 删除
  public removeCourse(id: string | number): Promise<any> {
    return this.http.delete(`/wecom-skill-backend/admin/course/remove/${id}`);
  }
  // 获取测评单下拉选
  public getDropdown(param?: Dict<any>): Promise<any> {
    return this.http.get('/wecom-skill-backend/admin/course/dropdown', param);
  }
  // 下载模板
  public async downLoadTemplate(filename: string): Promise<any> {
    return this.http.downloadFile(
      '/wecom-skill-backend/admin/course/template',
      {},
      filename
    );
  }
  // 导入
  public importFile(file: any): Promise<any> {
    const formData = new FormData();
    formData.append('file', file.raw);
    const headers = { 'Content-Type': 'multipart/form-data' };
    return this.http.post(
      `/wecom-skill-backend/admin/course/import`,
      formData,
      undefined,
      { headers }
    );
  }
  // 导出
  public async exportFile(param: Dict<any>): Promise<any> {
    return this.http.downloadFile(
      `/wecom-skill-backend/admin/course/export`,
      { title: param.title },
      param.filename
    );
  }
}
